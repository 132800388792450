import React from 'react';
import actions from './leads.actions';
import { FormattedMessage } from 'react-intl';
import AllLists from '../common/AllLists/AllLists';
//@ts-expect-error
const LeadsContext = React.createContext();

const leadsInitialState = {
  dataForm: {
    search: '',
    addedBy: '',
    //@ts-expect-error
    date: null,
    phone: '',
    page: 1,
    sortBy: 'descending',
    resultPerPage: 25,
    labelId: '',
    labelName: '',
    automationName: '',
  },
  //@ts-expect-error
  leads: null,
  pages: {},
  //@ts-expect-error
  selectedProfile: null,
  //@ts-expect-error
  labels: [],
  //@ts-expect-error
  selectedLabel: { name: <FormattedMessage id={'leads.lists'} />, id: null },
  //@ts-expect-error
  members: [],
  credits: {},
  loading: true,
  loadingLeads: true,
  //@ts-expect-error
  abortController: null,
  //@ts-expect-error
  leadDetails: null,
  //@ts-expect-error
  allTags: [],
  //@ts-expect-error
  selectedList: null,

  properties: [
    { name: ' Name', property: 'name', isFixed: true },
    { name: ' Job', property: 'job', isFixed: true },
    { name: ' Company', property: 'company', isFixed: true },

    //  { name: " Email", property: "emails", isChecked: true },

    {
      name: ' Professional Email',
      property: 'currentProEmail',
      isFixed: true,
    },
    {
      name: ' Personal Email',
      property: 'currentPersonalEmail',
      isFixed: true,
    },
    { name: ' Phone', property: 'phones', isFixed: true },
    { name: ' Added by', property: 'addedBy', isChecked: true },
    { name: ' List', property: 'list', isChecked: false },
    { name: ' Tags', property: 'tags', isChecked: false },

    { name: ' Description', property: 'description', isChecked: false },
    /*
    { name: " Comment", property: "comment", isChecked: false },
*/
    { name: ' Location', property: 'location', isChecked: false },
    { name: ' Company Name', property: 'companyInfo.name', isChecked: false },
    {
      name: ' Company Specialities',
      property: 'companyInfo.specialities',
      isChecked: false,
    },
    {
      name: ' Company Industries',
      property: 'companyInfo.industries',
      isChecked: false,
    },
    {
      name: ' Company Addresses',
      property: 'companyInfo.addresses',
      isChecked: false,
    },
    {
      name: ' Company Description',
      property: 'companyInfo.description',
      isChecked: false,
    },
    { name: ' Company Type', property: 'companyInfo.type', isChecked: false },
    {
      name: ' Company Staff Count',
      property: 'companyInfo.staffCount',
      isChecked: false,
    },
  ],
  waitingList: {
    loading: false,
    more: true,
    //@ts-expect-error
    data: [],
    after: 1,
    status: ['hasInfos', 'ignored', 'enriched'],
  },
  //@ts-expect-error
  waitingListNotifications: [],
  //@ts-expect-error
  waitingListPreLeads: null,
  //@ts-expect-error
  waitingListPreLeadsPages: null,
  //@ts-expect-error
  waitingListPreLeadsPage: null,
  //@ts-expect-error
  allTags: [],
  allTagsPages: {},
  //@ts-expect-error
  selectedListLeads: null,
};

const leadsReducer = (state: any, action: any) => {
  switch (action.type) {
    //@ts-expect-error
    case actions.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actions.GET_LEADS:
      return {
        ...state,
        leads: action.payload,
        pages: action.pages,
      };
    case actions.SELECT_LEAD:
      return {
        ...state,
        selectedProfile: action.payload,
      };
    case actions.SELECT_LABEL:
      return {
        ...state,
        selectedLabel: action.payload,
      };
    case actions.GET_LABELS:
      return {
        ...state,
        labels: action.payload,
      };
    case actions.GET_MEMBERS:
      return {
        ...state,
        members: action.payload,
      };
    case actions.LEADS_FILTER:
      const dataForm = { ...state.dataForm, ...action.payload };
      return {
        ...state,
        dataForm,
      };
    case actions.GET_CREDITS:
      return {
        ...state,
        credits: action.payload,
      };
    case actions.CHANGE_PROPERTIES:
      return {
        ...state,
        properties: action.payload,
      };

    case actions.RESET_PROPERTIES:
      const props = leadsInitialState.properties.map((item) => {
        if (
          item.property == 'name' ||
          item.property == 'job' ||
          item.property == 'emails' ||
          item.property == 'phones' ||
          item.property == 'addedBy'
        ) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
        return item;
      });
      return {
        ...state,
        properties: props,
      };

    case actions.LEADS_LOADING:
      return {
        ...state,
        leadsLoading: action.payload,
      };
    case actions.GET_WAITING_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case actions.START_WAITING_LIST_NOTIFICATIONS:
      return {
        ...state,
        ...action.payload,
      };
    case actions.GET_WAITING_LIST_NOTIFICATIONS:
      return {
        ...state,
        ...action.payload,
      };
    case actions.SELECT_LEAD:
      return {
        ...state,
        selectedProfile: action.payload,
      };
    case actions.LEAD_DETAILS:
      return {
        ...state,
        leadDetails: action.payload,
      };
    case actions.GET_ALL_TAGS:
      return {
        ...state,
        allTags: action.payload,
      };
    case actions.GET_ALL_TAGS_PAGES:
      return {
        ...state,
        allTagsPages: action.payload,
      };

    case actions.SET_SELECTED_LIST:
      return {
        ...state,
        selectedList: action.payload,
      };
    case actions.INIT_STATE:
      return action.payload;
    default:
      return state;
  }
};

export { LeadsContext, leadsInitialState, leadsReducer, actions };
