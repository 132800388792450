import React, { useContext, useEffect } from 'react';
import TagsList from '../../../common/TagsList/TagsList';

import { actions, LeadsContext } from '../../../store/leads.store';
import {
  getLeadFeatures,
  leadsFilter,
  tagLead,
  untagLead,
} from '../../../../../services/api/leads.api';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { checkPermission } from '../../../../../services/utils';
import { FormattedMessage } from 'react-intl';
import { Permissions } from '../../../../../components';
import { usePermissions } from '../../../../../components/Permissions/permissions.hooks';

function TagsBlock({
  selectToggle,
  setSelectToggle,
  profilePermissions,
  organizationPlanPermissions,
  profile,
  fromSideModal,
  organizationPlan,
}: any) {
  //@ts-expect-error
  const { state, dispatch } = useContext(LeadsContext);

  useEffect(() => {
    getLeadFeatures({ leadId: profile._id }).then((results) => {
      if (results.statusCode == 200) {
        dispatch({ type: actions.LEAD_DETAILS, payload: results.data });
      }
    });
  }, []);

  const [checkPlanPermissions, checkMembersPermissions] = usePermissions(
    organizationPlanPermissions,
    profilePermissions,
    'Leads',
  );

  const handleSelectTag = async (tag: any) => {
    if (!checkMembersPermissions('Edit')) {
      return toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }

    if (!checkPlanPermissions('Edit')) {
      return toast.error(<FormattedMessage id={'permissions.upgradePlan'} />);
    }
    const { statusCode, data } = await tagLead({
      tag: tag._id,
      clientDate: new Date(),
      leadIds: [profile._id],
    });
    if (statusCode == 200) {
      if (fromSideModal) {
        const leadsData = { ...state.dataForm };
        if (state.selectedList && state.selectedList._id) {
          leadsData.tagsIds = [state.selectedList._id];
        }

        const results = await leadsFilter({ data: leadsData });
        if (results.statusCode == 200) {
          //@ts-expect-error
          const selectedLead = results.data.leads.find((lead: any) => lead.email == profile.email);
          dispatch({
            type: actions.GET_LEADS,
            //@ts-expect-error
            payload: results.data.leads,
            //@ts-expect-error
            pages: results.data.pages,
          });
          dispatch({
            type: actions.LEAD_DETAILS,
            payload: { tags: selectedLead.tags },
          });
        }
      } else {
        const { statusCode: leadFeaturesStatus, data: leadFeaturesData } = await getLeadFeatures({
          leadId: profile._id,
        });
        if (leadFeaturesStatus == 200) {
          dispatch({ type: actions.LEAD_DETAILS, payload: leadFeaturesData });
        }
      }
    } else {
      //@ts-expect-error
      toast.error(data.message);
    }
  };

  const handleUntag = async (tag: any) => {
    if (!checkMembersPermissions('Edit')) {
      return toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }

    if (!checkPlanPermissions('Edit')) {
      return toast.error(<FormattedMessage id={'permissions.upgradePlan'} />);
    }

    const { statusCode, data } = await untagLead({
      tag: tag.tagId?._id,
      clientDate: new Date(),
      leadId: profile._id,
    });
    if (statusCode == 200) {
      if (fromSideModal) {
        const leadsData = { ...state.dataForm, page: 1 };
        if (state.selectedList && state.selectedList._id) {
          leadsData.tagsIds = [state.selectedList._id];
        }
        const results = await leadsFilter({ data: leadsData });
        if (results.statusCode == 200) {
          //@ts-expect-error
          const selectedLead = results.data.leads.find((lead: any) => lead.email == profile.email);
          dispatch({
            type: actions.GET_LEADS,
            //@ts-expect-error
            payload: results.data.leads,
            //@ts-expect-error
            pages: results.data.pages,
          });
          dispatch({
            type: actions.LEAD_DETAILS,
            payload: { tags: selectedLead.tags },
          });
        }
      } else {
        const { statusCode: leadFeaturesStatus, data: leadFeaturesData } = await getLeadFeatures({
          leadId: profile._id,
        });
        if (leadFeaturesStatus == 200) {
          dispatch({ type: actions.LEAD_DETAILS, payload: leadFeaturesData });
        }
      }
    } else {
      //@ts-expect-error
      toast.error(data.message);
    }
  };

  return (
    <div className="tags-block">
      <div
        style={{
          ...(state.leadDetails && state.leadDetails.tags && state.leadDetails.tags.length > 0
            ? { border: '1px dashed #ddd' }
            : {}),
          padding: 10,
          borderRadius: 10,
        }}
      >
        {!checkPlanPermissions('Edit') || !checkMembersPermissions('Edit') ? (
          <Permissions.CallToAction
            withoutIcon
            toolTipStyle={fromSideModal ? { width: '300px', marginLeft: 40 } : {}}
            isPlan={!checkPlanPermissions('Edit')}
          >
            <div id={'tags_list'} className={'tag-fields cstm-dropdown  add-to-list '}>
              <div className={'custom-title'}>
                <FormattedMessage id="leads.lists" />
              </div>

              <div className="white-button">
                <img
                  src="/assets/images/ic-add.svg"
                  alt=""
                  style={{
                    width: 20,
                    height: 20,
                    marginRight: 10,
                    opacity: 0.6,
                  }}
                />

                <p style={{ width: 'max-content', fontSize: 14 }}>
                  <FormattedMessage id="leads.lists" />
                </p>
              </div>
            </div>
          </Permissions.CallToAction>
        ) : (
          <>
            <TagsList
              leadId={profile._id}
              selectTag={handleSelectTag}
              formSideModal={fromSideModal}
              maxTags={organizationPlan && organizationPlan.maxTags}
            />
            {state.leadDetails && state.leadDetails.tags && state.leadDetails.tags.length > 0 && (
              <div className={'lead-tags-container'}>
                {state.leadDetails.tags.map((tag: any, i: any) => (
                  <span key={i} style={{ background: tag.color + '1A', color: tag.tagId?.color }}>
                    <span> {tag.tagId?.value}</span>

                    <i className="fas fa-times" onClick={() => handleUntag(tag)}></i>
                  </span>
                ))}
              </div>
            )}
          </>
        )}
      </div>

      {/*  <a href="leads-detail.html" className="view-detail-btn">
                    View details
                  </a>*/}
    </div>
  );
}
export default TagsBlock;
