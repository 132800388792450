import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import LeadsNav from '../common/LeadsNav/LeadsNav';
import DataNotFound from '../../../components/DataNotFound/DataNotFound';
import LeadsListFilter from '../common/LeadsList/LeadsListFilter/LeadsListFilter';
import LeadsList from '../common/LeadsList/LeadsList';
import IntegrationsPopUp from '../../Workflow/common/IntegrationsPopUp/IntegrationsPopUp';
import { toast, ToastContainer } from 'react-toastify';
import './LeadsPanel.css';
import { actions, LeadsContext } from '../store/leads.store';
import { checkPermission, checkIsPlanLimited } from '../../../services/utils/checkPermissions';
import ProfileSideModal from '../LeadsInfo/ProfileSideModal/ProfileSideModal';
import { getAccounts } from '../../../services/api/integrations.api';
import useProfile from '../LeadsInfo/Hooks/Profile.hooks';
import { PaginationInput } from '../../../components';
import { leadsFilter, updateLeadInfos } from '../../../services/api/leads.api';
import { getProfileData } from '../../../services/api/profile.api';

export default function LeadsPanel({
  organizationCredits,
  planPermissions,
  credits,
  profilePermissions,
  organization,
  exportRemaining,
  hasPermission,
  organizationPlanPermissions,
  setOrganization,
  errorMessage,
  profile,
  checkPlanPermissions,
  checkMemberPermissions,
  isVersion2,
  remainingCredits,
  hidePersonalEmails,
  selectedTab,
}: any) {
  //@ts-expect-error
  const { state, dispatch } = useContext(LeadsContext);

  const [account, setAccount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [triggerGetUpdatedLeads, setTriggerUpdatedLeads] = useState(false);

  const [
    {
      //@ts-expect-error
      loader,
      page,
      exportLeadToIntegration,
      emailToggle,
      deleteLeadErrorMessage,
      permissionToggle,
      selectedLead,
    },
    {
      handleLoader,
      setCounter,
      setPage,
      setEmailToggle,
      setExportLeadToIntegration,
      setDeleteLeadErrorMessage,
      setPermissionToggle,
      setSelectedLead,
    },
    { handleExportLeads, handleDeleteLead, handleEmailToggle, handleGoToPage },
  ] = useProfile({ planPermissions, profilePermissions, profile, organizationCredits, account });

  const planIsLimitedPermission = checkIsPlanLimited({
    permissions: planPermissions,
    name: 'Leads',
  });

  const memberPermission = checkPermission({
    permissions: profilePermissions,
    name: 'Leads',
    action: 'Export',
  });
  const planPermission = checkPermission({
    permissions: planPermissions,
    name: 'Leads',
    action: 'Export',
  });

  useEffect(() => {
    const fetchAccount = async () => {
      const { data } = await getAccounts();
      //@ts-expect-error
      if (data && data.accounts && data.accounts.gmail && data.accounts.gmail.gmailEmail) {
        setAccount(true);
      }
    };
    fetchAccount();

    return () => {
      setAccount(false);
    };
  }, []);

  // // Get leads from selectedList
  useEffect(() => {
    const fetchLeads = async () => {
      setLoading(true);
      dispatch({ type: actions.LEADS_LOADING, payload: true });

      let leadsData;
      if (state.selectedList && state.selectedList._id) {
        leadsData = { ...state.dataForm, page: 1, tagsIds: [state.selectedList._id] };
      }

      if (selectedTab === 'allLeads') {
        leadsData = {
          search: '',
          addedBy: '',
          date: null,
          phone: '',
          page: 1,
          sortBy: 'descending',
          resultPerPage: 25,
          labelId: '',
          labelName: '',
          automationName: '',
          tagsIds: null,
        };
      }

      const results = await leadsFilter({ data: leadsData });

      //@ts-expect-error
      if (results.data.leads && results.data.leads.length > 0) {
        dispatch({
          type: actions.GET_LEADS,
          //@ts-expect-error
          payload: results.data.leads,
          //@ts-expect-error
          pages: results.data.pages,
        });
      } else {
        dispatch({
          type: actions.GET_LEADS,
          payload: null,
          //@ts-expect-error
          pages: results.data.pages,
        });
      }

      setLoading(false);
      dispatch({ type: actions.LEADS_LOADING, payload: false });
    };

    fetchLeads();

    return () => {
      if (selectedTab === 'allLeads') {
        dispatch({
          type: actions.SET_SELECTED_LIST,
          payload: null,
        });
      }
    };
  }, [selectedTab, triggerGetUpdatedLeads]);

  const selectedLeads = () => {
    const count = (state.leads && state.leads.filter((lead: any) => lead.isChecked).length) || 0;
    return count === 0 ? false : count;
  };

  return (
    <div id={'leads_panel'} className={'tab-pane fade active show '}>
      {loader}
      {state.leadsLoading && (
        <div className="loader-inner-wrapper">
          <span>
            <img width={60} src="/assets/images/logo_round.png" alt="" />
            <p className="loader-icon" style={{ color: '#ccc', width: 100 }}>
              <FormattedMessage id={'loading'} />
            </p>
          </span>
        </div>
      )}
      {state.leads?.length === 0 && !state.leadsLoading ? (
        <>
          <LeadsNav organizationCredit={organizationCredits} setPage={setPage} />
          <ToastContainer />
          <DataNotFound
            style={{ paddingTop: '30px' }}
            image={'/assets/images/img_empty_no_leads.png'}
            elements={
              <div
                style={{ marginTop: '0' }}
                className="join-organization-inner join-organization-page"
              >
                <h4>
                  {state.dataForm.date || state.dataForm.search ? (
                    <FormattedMessage id={'leads.noLeadsFound'} />
                  ) : (
                    <FormattedMessage id={'leads.noLeadsYet'} />
                  )}
                </h4>

                <p className="desc">
                  <FormattedMessage id={'leads.youCanAddNewLeads'} />
                </p>

                <a
                  href={'https://linkedin.com'}
                  target={'_blank'}
                  style={{ color: 'white' }}
                  className={'btn1'}
                  data-tracking-id={'leads.noLeadsFound-goToLinkedIn'}
                  rel="noopener noreferrer nofollow"
                >
                  <FormattedMessage id={'leads.goToLinkedin'} />
                </a>
              </div>
            }
          />
        </>
      ) : (
        <>
          <LeadsNav organizationCredit={organizationCredits} setPage={setPage} />
          <ToastContainer />
          <div className="table-wrap" style={{ marginBottom: 70 }}>
            <LeadsListFilter
              organizationCredit={organizationCredits}
              page={page}
              setPage={setPage}
              handleGoToPage={handleGoToPage}
              profile={profile}
              handleLoader={handleLoader}
              setCounter={setCounter}
              handleExportLeads={handleExportLeads}
              leads={state.leads}
              setTriggerUpdatedLeads={setTriggerUpdatedLeads}
            />
            {!state.leadsLoading && (
              <LeadsList
                handleExportLeads={handleExportLeads}
                handleEmailToggle={handleEmailToggle}
                emailToggle={emailToggle}
                setPermissionToggle={setPermissionToggle}
                permissionToggle={permissionToggle}
                setSelectedLead={setSelectedLead}
                selectedLead={selectedLead}
                isVersion2={isVersion2}
                organization={organization}
                hidePersonalEmails={hidePersonalEmails}
                leads={state.leads}
              />
            )}
            {exportLeadToIntegration && (
              <IntegrationsPopUp
                exportRemaining={exportRemaining}
                hasPermission={hasPermission}
                organizationCredits={organizationCredits}
                toggle={exportLeadToIntegration}
                setToggle={setExportLeadToIntegration}
                filters={state.dataForm}
                leads={exportLeadToIntegration}
                showToastMessage={(response: any, type: any) => {
                  type === 'success'
                    ? toast.success(response)
                    : type === 'info'
                      ? toast.info(response)
                      : toast.error(response);
                }}
                isVersion2={isVersion2}
              />
            )}
            {state.selectedProfile && !state.selectedProfile.isViewDetails && (
              <ProfileSideModal
                profile={state.selectedProfile}
                setModalToggle={() => {
                  dispatch({ type: actions.SELECT_LEAD, payload: null });
                  setDeleteLeadErrorMessage(null);
                }}
                handleDeleteLead={handleDeleteLead}
                handleEmailToggle={(lead: any) => {
                  handleEmailToggle(lead);
                  dispatch({ type: actions.SELECT_LEAD, payload: null });
                }}
                handleExportLeads={handleExportLeads}
                deleteLeadErrorMessage={deleteLeadErrorMessage}
                organization={organization}
                profilePermissions={profilePermissions}
                organizationPlanPermissions={planPermissions}
                organizationCredits={organizationCredits}
                checkPlanPermissions={checkPlanPermissions}
                checkMemberPermissions={checkMemberPermissions}
                isVersion2={isVersion2}
              />
            )}
            <div className="leads-table-footer">
              {!selectedLeads() && (
                <div style={{ display: 'flex' }} data-tracking-id={'leads.filters-pagination'}>
                  <label style={{ margin: '14px 12px 0px 12px', color: '#595F79' }}>
                    <FormattedMessage id={'page'} /> :
                  </label>

                  <div style={{ marginTop: 5 }}>
                    <PaginationInput
                      pages={
                        state.selectedListPages
                          ? state.selectedListPages && state.selectedListPages.pages
                          : state.pages && state.pages.pages
                      }
                      currentPage={page}
                      onChange={handleGoToPage}
                      lock={planIsLimitedPermission}
                    />
                  </div>

                  <label style={{ margin: '14px 12px 0px 12px', color: '#595F79' }}>
                    <FormattedMessage
                      id={'ofPages'}
                      values={{
                        value: state.selectedListPages
                          ? state.selectedListPages && state.selectedListPages.pages
                          : state.pages && state.pages.pages,
                      }}
                    />
                  </label>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
