import React, { useReducer, useMemo, useEffect, useState } from 'react';

import { leadsReducer, leadsInitialState, actions } from './store/leads.store';
import LeadsProvider from './store/leads.provider';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import './Leads.css';
import { ContentWrapperUi, Header, SideNav, PreLoader } from '../../components';
import { getLeadFeatures, getWaitingList, leadsFilter } from '../../services/api/leads.api';
import LeadsContentWrapper from './common/LeadsContentWrapper/LeadsContentWrapper';
import { getMembers } from '../../services/api/organization.api';
import { getLabels } from '../../services/api/labels.api';
import DataNotFound from '../../components/DataNotFound/DataNotFound';
import { FormattedMessage, useIntl } from 'react-intl';
import LeadsPanel from './LeadsPanel/LeadsPanel';
import WaitingList from './waitingList/waitingList';
import queryString from 'query-string';
import LeadsDetails from './LeadsInfo/LeadDetails/LeadDetails';
import { getAccounts } from '../../services/api/integrations.api';
import * as Permissions from '../../components/Permissions/Permissions';
import { usePermissions } from '../../components/Permissions/permissions.hooks';
import AllLists from './common/AllLists/AllLists';

function Leads(props: any) {
  const [state, dispatch] = useReducer(leadsReducer, leadsInitialState);
  const [organization, setOrganization] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [account, setAccount] = useState(false);
  const [hidePersonalEmails, setHidePersonalEmails] = useState(false);
  const [permissionError, setPermissionError] = useState(false);
  const { leadId, tabId } = useParams();
  const [selectedTab, setSelectedTab] = useState(
    tabId === 'waitinglist' ? 'waitinglist' : 'allLeads',
  );
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);
  const [checkPlanPermissions, checkMemberPermissions] = usePermissions(
    props.redux.credits.organizationCredits &&
      props.redux.credits.organizationCredits.plan.permissions,
    props.redux.profile.permissions,
    'Leads',
  );
  const intl = useIntl();

  let navigate = useNavigate();

  useEffect(() => {
    const org = props.redux.organization && props.redux.organization.organization;
    const user = props.redux.profile && props.redux.profile;
    const organizationCredits = props.redux.credits.organizationCredits;
    if (org) {
      setHidePersonalEmails(org.hidePersonalEmail);
    } else {
      setHidePersonalEmails(user.hidePersonalEmail);
    }
  }, [props.redux]);

  useEffect(() => {
    if (!checkPlanPermissions('View')) {
      //@ts-expect-error
      return setPermissionError('member_permissions');
    }

    if (!checkPlanPermissions('View')) {
      //@ts-expect-error
      return setPermissionError('plan_permissions');
    }
    //@ts-expect-error
    getWaitingList().then(({ data, statusCode }: any) => {
      if (statusCode == 200) {
        dispatch({
          type: actions.GET_WAITING_LIST,
          payload: {
            waitingListPreLeads: data.preLeads,

            waitingListPreLeadsPages: data.pages,

            waitingListPreLeadsPage: data.pages.page,
          },
        });
      } else {
        dispatch({
          type: actions.GET_WAITING_LIST,
          payload: { activity: 'not_allowed' },
        });
      }
    });
    getAccounts().then(({ data }: any) => {
      if (data && data.accounts && data.accounts.gmail && data.accounts.gmail.gmailEmail)
        setAccount(true);
    });
    if (leadId) {
      getLeadFeatures({ leadId }).then(({ data, statusCode }: any) => {
        if (statusCode == 200) {
          if (!data.lead) navigate('/leads');
          const { notes, tasks } = queryString.parse(window.location.search);

          data.lead.isViewDetails = true;

          if (notes === 'true') data.lead.isViewNotes = true;
          else if (tasks === 'true') data.lead.isViewTasks = true;

          dispatch({ type: actions.SELECT_LEAD, payload: data.lead });
          dispatch({ type: actions.LEAD_DETAILS, payload: data });
        } else {
          navigate('/leads');
        }
      });
    }
    getLabels().then((data: any) => {
      dispatch({ type: actions.GET_LABELS, payload: data.labels });
    });

    const org = props.redux.organization.organization;
    if (org) {
      setOrganization(org);
      if (org.settings.leadsColumns && org.settings.leadsColumns.length > 0) {
        let columns = org.settings.leadsColumns.map((orgProperty: any) => {
          const propertyFound = state.properties.find(
            (prop: any) => prop.property === orgProperty.property,
          );
          if (propertyFound) {
            orgProperty.isFixed = !!propertyFound.isFixed;
          }
          return orgProperty;
        });
        dispatch({
          type: actions.CHANGE_PROPERTIES,
          payload: columns,
        });
      }
      getMembers({ search: '', page: 1 }).then(({ data, statusCode }: any) => {
        dispatch({
          type: actions.GET_MEMBERS,

          payload: data.members,
        });
      });

      dispatch({ type: actions.GET_CREDITS, payload: props.redux.credits });
    } else {
      setOrganization(false);
    }
    dispatch({
      type: actions.LEADS_LOADING,
      payload: false,
    });
    if (props.redux.organization.tags) {
      dispatch({
        type: actions.GET_ALL_TAGS,
        payload: props.redux.organization.tags,
      });
    }
    return () => dispatch({ type: actions.INIT_STATE, payload: leadsInitialState });
  }, []);

  if (!checkPlanPermissions('View'))
    return (
      <LeadsProvider store={store}>
        <div className="main-wraper">
          <Header
            redux={props.redux}
            currentPage={<FormattedMessage id={'sidenav.leads'} />}
            profile={props.redux.profile}
            credits={props.redux.credits}
            remaining={props.redux.credits.remaining}
            organization={props.redux.organization}
          />

          <ContentWrapperUi>
            <SideNav navToggle={props.redux.globalReducer.navToggle} />

            <LeadsContentWrapper navToggle={props.redux.globalReducer.navToggle}>
              <Permissions.View
                title={intl.formatMessage({ id: 'permissions.denied' })}
                description={intl.formatMessage({ id: 'permissions.noAccess' })}
                img={'leads-bg.png'}
              />
            </LeadsContentWrapper>
          </ContentWrapperUi>
        </div>
      </LeadsProvider>
    );

  if (!checkMemberPermissions('View'))
    return (
      <LeadsProvider store={store}>
        <div className="main-wraper">
          <Header
            redux={props.redux}
            currentPage={<FormattedMessage id={'sidenav.leads'} />}
            profile={props.redux.profile}
            credits={props.redux.credits}
            remaining={props.redux.credits.remaining}
            organization={props.redux.organization}
          />

          <ContentWrapperUi>
            <SideNav navToggle={props.redux.globalReducer.navToggle} />

            <LeadsContentWrapper navToggle={props.redux.globalReducer.navToggle}>
              <Permissions.View
                title={intl.formatMessage({ id: 'permissions.denied' })}
                description={intl.formatMessage({ id: 'permissions.noAccess' })}
                img={'leads-bg.png'}
              />
            </LeadsContentWrapper>
          </ContentWrapperUi>
        </div>
      </LeadsProvider>
    );
  if (organization == null) {
    return <PreLoader />;
  }
  if (leadId && !state.selectedProfile) {
    return <PreLoader />;
  }

  if (!organization) {
    return (
      <LeadsProvider store={store}>
        <div className="main-wraper">
          <Header
            redux={props.redux}
            currentPage={<FormattedMessage id={'sidenav.leads'} />}
            profile={props.redux.profile}
            credits={props.redux.credits}
            remaining={props.redux.credits.remaining}
            organization={props.redux.organization}
          />

          <ContentWrapperUi>
            <SideNav navToggle={props.redux.globalReducer.navToggle} />

            <LeadsContentWrapper navToggle={props.redux.globalReducer.navToggle}>
              <DataNotFound
                image={'/assets/images/img_empty_no_leads.png'}
                elements={
                  <div
                    style={{ marginTop: '0' }}
                    className="join-organization-inner join-organization-page"
                  >
                    {props.redux.profile.joinRequestSent &&
                    props.redux.profile.joinRequestSent.joinReqs &&
                    props.redux.profile.joinRequestSent.joinReqs.length > 0 ? (
                      <>
                        <h4>
                          <FormattedMessage id={'organization.youArentPartOfAWkYet'} />
                        </h4>

                        <p className="desc">
                          <FormattedMessage id={'organization.youArentPartOfAWkYetText'} />
                        </p>

                        <button className={'btn1'}>
                          <NavLink
                            to={'/workspace'}
                            style={{ color: 'white' }}
                            data-tracking-id={'leads.createOrganization'}
                          >
                            <FormattedMessage id={'organization.viewPendingRequest'} />
                          </NavLink>
                        </button>
                      </>
                    ) : (
                      <>
                        <h4>
                          <FormattedMessage id={'organization.youHavetoCreateAnOrganization'} />
                        </h4>

                        <p className="desc">
                          <FormattedMessage
                            id={'organization.youHavetoCreateAnOrganization.text'}
                          />
                        </p>

                        <button className={'btn1'}>
                          <NavLink
                            to={'/workspace'}
                            style={{ color: 'white' }}
                            data-tracking-id={'leads.createOrganization'}
                          >
                            <FormattedMessage id={'organization.showWorkspaces'} />
                          </NavLink>
                        </button>
                      </>
                    )}
                  </div>
                }
              />
            </LeadsContentWrapper>
          </ContentWrapperUi>
        </div>
      </LeadsProvider>
    );
  }

  return (
    <LeadsProvider store={store}>
      <div className="main-wraper">
        <Header
          redux={props.redux}
          currentPage={<FormattedMessage id={'sidenav.leads'} />}
          profile={props.redux.profile}
          credits={props.redux.credits}
          remaining={props.redux.credits.remaining}
          isLeadPage={state.selectedProfile && state.selectedProfile.isViewDetails}
          backToLeads={() => {
            dispatch({ type: actions.SELECT_LEAD, payload: null });
          }}
          organization={props.redux.organization}
        />

        <ContentWrapperUi>
          <SideNav navToggle={props.redux.globalReducer.navToggle} />

          <LeadsContentWrapper navToggle={props.redux.globalReducer.navToggle}>
            {(state.selectedProfile && state.selectedProfile.isViewDetails) || leadId ? (
              <LeadsDetails
                {...props}
                leadId={leadId}
                profile={state.selectedProfile}
                user={props.redux.profile}
                organization={props.redux.organization}
                account={account}
                checkPlanPermissions={checkPlanPermissions}
                checkMemberPermissions={checkMemberPermissions}
                isVersion2={props.redux.credits && props.redux.credits.isVersion(2)}
              />
            ) : (
              <>
                <NavTab
                  organization={props.redux.organization}
                  profile={props.redux.profile}
                  urlSegment={props.urlSegment}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
                {selectedTab === 'allLeads' ? (
                  <LeadsPanel
                    organizationCredits={
                      props.redux.credits && props.redux.credits.organizationCredits
                    }
                    planPermissions={
                      props.redux.credits.organizationCredits &&
                      props.redux.credits.organizationCredits.plan.permissions
                    }
                    credits={props.redux.credits}
                    profilePermissions={props.redux.profile && props.redux.profile.permissions}
                    organization={props.redux.organization && props.redux.organization.organization}
                    exportRemaining={
                      props.remainingCredits &&
                      props.remainingCredits.organizationCredits &&
                      props.remainingCredits.organizationCredits.exportCredits
                    }
                    hasPermission={
                      props.redux.organizationCredit &&
                      !!props.redux.organizationCredit.plan.permissions.find(
                        (permission: any) =>
                          permission.name == 'Integration' && permission.actions.includes('Export'),
                      )
                    }
                    organizationPlanPermissions={
                      props.redux.credits &&
                      props.redux.credits.organizationCredits &&
                      props.redux.credits.organizationCredits.plan.permissions
                    }
                    setOrganization={setOrganization}
                    errorMessage={errorMessage}
                    profile={props.redux.profile}
                    checkPlanPermissions={checkPlanPermissions}
                    checkMemberPermissions={checkMemberPermissions}
                    isVersion2={props.redux.credits && props.redux.credits.isVersion(2)}
                    remainingCredits={props.redux.credits && props.redux.credits.remaining}
                    hidePersonalEmails={hidePersonalEmails}
                    selectedList={null}
                    selectedTab={selectedTab}
                  />
                ) : selectedTab === 'lists' ? (
                  <AllLists
                    organizationCredits={
                      props.redux.credits && props.redux.credits.organizationCredits
                    }
                    planPermissions={
                      props.redux.credits.organizationCredits &&
                      props.redux.credits.organizationCredits.plan.permissions
                    }
                    credits={props.redux.credits}
                    profilePermissions={props.redux.profile && props.redux.profile.permissions}
                    organization={props.organization && props.organization.organization}
                    exportRemaining={
                      props.remainingCredits &&
                      props.remainingCredits.organizationCredits &&
                      props.remainingCredits.organizationCredits.exportCredits
                    }
                    hasPermission={
                      props.redux.organizationCredit &&
                      !!props.redux.organizationCredit.plan.permissions.find(
                        (permission: any) =>
                          permission.name == 'Integration' && permission.actions.includes('Export'),
                      )
                    }
                    organizationPlanPermissions={
                      props.redux.credits &&
                      props.redux.credits.organizationCredits &&
                      props.redux.credits.organizationCredits.plan.permissions
                    }
                    setOrganization={setOrganization}
                    errorMessage={errorMessage}
                    profile={props.redux.profile}
                    checkPlanPermissions={checkPlanPermissions}
                    checkMemberPermissions={checkMemberPermissions}
                    isVersion2={props.redux.credits && props.redux.credits.isVersion(2)}
                    remainingCredits={props.redux.credits && props.redux.credits.remaining}
                    hidePersonalEmails={hidePersonalEmails}
                    selectedTab={selectedTab}
                  />
                ) : selectedTab === 'waitinglist' ? (
                  <WaitingList {...props} />
                ) : null}
              </>
            )}
          </LeadsContentWrapper>
        </ContentWrapperUi>
      </div>
    </LeadsProvider>
  );
}
function NavTab({ urlSegment, setSelectedTab, selectedTab }: any) {
  const navigate = useNavigate();
  const onTabCLick = (tab: any) => {
    navigate('/leads/' + tab);
    setSelectedTab(tab);
  };
  return (
    <div className="workflow-detail-header" style={{ marginBottom: '2rem' }}>
      <div className="cstm-tabs-ui cs-tabs-slider">
        <ul className="nav nav-tabs">
          <li onClick={() => onTabCLick('allLeads')}>
            <a id={'leads-a'} className={selectedTab === 'allLeads' ? 'active' : ''}>
              <FormattedMessage id={'leads.allLeads'} />
            </a>
          </li>
          <li onClick={() => onTabCLick('lists')}>
            <a id={'leads-a'} className={selectedTab === 'lists' ? 'active' : ''}>
              <FormattedMessage id={'leads.lists'} />
            </a>
          </li>

          <li onClick={() => onTabCLick('waitinglist')}>
            <a id={'waitingList'} className={selectedTab === 'waitinglist' ? 'active' : ''}>
              <FormattedMessage id={'organization.waitingList'} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Leads;
